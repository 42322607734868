import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

// Define the styled DataGrid component with your custom styles
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
    textAlign: "center",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#000000", // Black background for the column header
    color: "#ffffff", // White text for the column header
  },
  "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
    color: "#ffffff", // White arrow (sort indicator)
  },
  "& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon": {
    color: "#ffffff", // White three dots (column menu button)
  },
  "& .MuiDataGrid-cell": {
    whiteSpace: "normal",
    lineHeight: "normal",
    alignContent: "center",
  },
  // Define your custom styles for even and odd rows
  "& .row-odd": {
    backgroundColor: "#f5f5f5", // Light gray for odd rows
  },
  "& .row-even": {
    backgroundColor: "#ffffff", // White for even rows
  },
}));

function CustomDataTable({
  rows,
  columns,
  paginationModel,
  onPaginationModelChange,
  ...props
}) {
  const getRowClassName = (params) => {
    const { indexRelativeToCurrentPage } = params;
    return indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd";
  };

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      components={{ Toolbar: GridToolbar }}
      pagination
      paginationModel={paginationModel}
      pageSizeOptions={[50, 75, 100]}
      onPaginationModelChange={onPaginationModelChange}
      sortingOrder={["asc", "desc", null]}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      getRowClassName={getRowClassName}
      hideFooterSelectedRowCount
      {...props}
    />
  );
}

// Export the function so that it can be imported in other files
export default CustomDataTable;
