import React, { useEffect, useState } from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import { useSelector } from "react-redux";
import {
  FaFileContract,
  FaUser,
  FaFileInvoiceDollar,
  FaUpload,
  FaRegFile,
} from "react-icons/fa";
import { getDistinctFiscalYears } from "../../services/getSonServices";
import { Link } from "react-router-dom";

const SonSideBar = (
  image,
  { collapsed },
  rtl,
  toggled,
  handleToggleSidebar
) => {
  const [soNFiscalYear, setSoNFiscalYear] = useState();
  const environment = process.env.REACT_APP_ENV;
  useEffect(() => {
    const fetchFiscalYears = async () => {
      try {
        const { data } = await getDistinctFiscalYears();
        const fy = Math.max(...data);
        setSoNFiscalYear(fy);
      } catch (error) {
        console.error("Error fetching fiscal years:", error);
      }
    };

    fetchFiscalYears();
  }, []); // Empty dependency array to run only once on mount

  if (!soNFiscalYear) return null;
  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={false}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {environment === "Demo" ? "Care Solutions" : "PSSF"}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem>Award Dashboard Current FY</MenuItem>
          <MenuItem icon={<FaFileInvoiceDollar />}>
            <Link to="/provider/awardinformation" rel="noopener noreferrer">
              Award Information
            </Link>
          </MenuItem>
          {/* <MenuItem icon={<FaInfo />}>
            <Link to="/provider/contractprep" rel="noopener noreferrer">
              Contract Prep Meeting Information
            </Link>
          </MenuItem> */}
          <MenuItem icon={<FaUpload />}>
            <Link to="/provider/contractprep" rel="noopener noreferrer">
              Contract Preparation
            </Link>
          </MenuItem>
          <MenuItem icon={<FaRegFile />}>
            <a href="/provider/resourceguide" rel="noopener noreferrer">
              Resource Guide
            </a>
            {/* <a href="https://pssfnet.com/content/guide/" target="_blank">
              Resource Guide
            </a> */}
          </MenuItem>
          {/*           <MenuItem icon={<FaUsers />}>
            <Link
              to="/provider/contractorsmeetingreginfo"
              rel="noopener noreferrer"
            >
              Contractors Meeting Registration Information
            </Link>
          </MenuItem>
          <MenuItem icon={<FaDumbbell />}>
            <Link to="/provider/traininginfo" rel="noopener noreferrer">
              PSSFWeb Training Information
            </Link>
          </MenuItem> */}
          <MenuItem icon={<FaFileContract />}>
            <Link to="/provider/currentcontract" rel="noopener noreferrer">
              FFY{soNFiscalYear} Contract
            </Link>
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <FaUser />
          <Link
            to="/account/logout"
            rel="noopener noreferrer"
            className="text-white"
          >
            Logout
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SonSideBar;
