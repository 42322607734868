import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FaPlusCircle } from "react-icons/fa";
import { CreateUser } from "../../services/accountservice";
import OutlinedInput from "@mui/material/OutlinedInput";
import auth from "../../services/authService";
import userPrograms, {
  postUserProgramsById,
} from "../../services/userPrograms";
import Spinner from "react-bootstrap/Spinner";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(provider, providerUser, theme) {
  return {
    fontWeight:
      providerUser.indexOf(provider) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ProviderUserModal = () => {
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({});
  const [hasError, setHasError] = useState(false);
  const [hasProgramError, setHasProgramError] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [providers, setProviders] = useState([]);
  const [userProviders, setUserProviders] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const theme = useTheme();

  const handleSave = async (event) => {
    const form = document.getElementById("form1");

    setHasError(false);
    if (!data.type) {
      setHasError(true);
    }

    setHasProgramError(false);
    if (userProviders.length === 0) {
      setHasProgramError(true);
    }

    if (form.checkValidity() === false || hasError || hasProgramError) {
      event.preventDefault();
      event.stopPropagation();
      window.scrollTo(0, 0); // Scroll to the top for errors
    } else {
      setLoading(true); // Start spinner
      await saveData();
      setLoading(false); // Stop spinner
    }
    setValidated(true);
  };

  async function saveData() {
    try {
      const user = await auth.getCurrentUser();
      await CreateUser(data);
      await postUserProgramsById(data.userName, userProviders);
      setShowUser(false);
      window.location.reload(false); // Refresh menu after user creation
    } catch (error) {
      console.error("Error creating user:", error);
    }
  }

  const handleClose = () => {
    setData({});
    setShowUser(false);
  };

  const handleShow = () => {
    fetchData();
    setShowUser(true);
  };

  const handleChangeProgram = (event) => {
    const {
      target: { value },
    } = event;

    var uProvider = [];
    var len = value.length;
    for (var i = 0; i < len; i++) {
      if (typeof value[i] === "number") {
        uProvider.push(value[i].toString());
      }
    }

    setUserProviders(uProvider);
    setProviders(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name || event.target.id;
    const copyOfObject = { ...data };
    delete copyOfObject[name];

    const updatedValue = { [name]: event.target.value, role: "Provider" };

    if (name === "userName") {
      updatedValue["email"] = event.target.value;
    }

    setData({ ...copyOfObject, ...updatedValue });
  };

  async function fetchData() {
    const user = await auth.getCurrentUser();
    const { data: program } = await userPrograms.getUserProgramsById(
      user.UserId
    );
    setProviders(program);
  }

  return (
    <>
      <button className="btn btn-link text-success" onClick={handleShow}>
        <FaPlusCircle />
        <span className="ms-2">Add User Now</span>
      </button>
      <Modal show={showUser} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="form1">
            {/* Name */}
            <Form.Group className="mb-2" controlId="name">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                defaultValue={data.name}
                onChange={handleChange}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a name.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Email */}
            <Form.Group className="mb-2" controlId="userName">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                className="form-control"
                defaultValue={data.userName}
                onChange={handleChange}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Password */}
            <Form.Group className="mb-2" controlId="password">
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                className="form-control"
                defaultValue={data.password}
                onChange={handleChange}
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                Please provide a password.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Type */}
            <Form.Group className="my-4" controlId="type">
              <FormControl
                required
                sx={{ m: 0, width: 400 }}
                size="small"
                error={hasError}
              >
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="type"
                  value={`${data.type}`}
                  label="type"
                  name="type"
                  onChange={handleChange}
                >
                  <MenuItem value={"ED"}>Executive Director</MenuItem>
                  <MenuItem value={"PC"}>Program Coordinator</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
                {hasError && <FormHelperText>Type is required!</FormHelperText>}
              </FormControl>
            </Form.Group>

            {/* Programs */}
            <Form.Group className="my-4" controlId="programId">
              <FormControl
                required
                sx={{ m: 0, width: 400 }}
                size="small"
                error={hasProgramError}
              >
                <InputLabel id="demo-multiple-name-label">
                  Program(s)
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={providers}
                  onChange={handleChangeProgram}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                  required
                >
                  {providers.map((provider, i) => (
                    <MenuItem
                      key={i}
                      value={provider.programId}
                      style={getStyles(provider, providers, theme)}
                    >
                      {provider.name}
                    </MenuItem>
                  ))}
                </Select>
                {hasProgramError && (
                  <FormHelperText>
                    You must select at least one program.
                  </FormHelperText>
                )}
              </FormControl>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {loading ? (
              <Spinner as="span" animation="border" size="sm" />
            ) : (
              "Create User"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProviderUserModal;
