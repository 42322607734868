import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Moment from "moment";
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";
import {
  getProviderStaffById,
  getProviderStaffByProgramId,
  updateProviderStaff,
} from "../../services/providerStaffService";
import authService from "../../services/authService";
import StaffModal from "./staffmodal";
import Button from "react-bootstrap/Button";


const StaffQualifications = () => {
  const [staff, setStaff] = useState({});
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [validated, setValidated] = useState(false);

  const user = authService.getCurrentUser();

  let Role = user.role;

  const pfyId = sessionStorage.getItem("ProgramByFiscalYearId");
  const programId = sessionStorage.getItem("ProgramId");

  async function fetchData() {
    const { data: staff } = await getProviderStaffByProgramId(programId);
    
    setStaff(staff);
  }

  const handleClose = () => {
    setData({});
    setValidated(false); 
    setShow(false);
  };

  const handleChange = (event) => {
    const name = event.target.id;
    const newData = { ...data };
    newData[name] = event.target.value;
    setData(newData);
  };

  const handleCheck = (event) => {
    const name = event.target.name;
    const newData = { ...data };
    newData[name] = !newData[name];
    setData(newData);
  };

  async function fetchDataById(id) {
    const { data } = await getProviderStaffById(id);
    setData(data);
  }

  async function handleSave(event) {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      await updateProviderStaff(data.id, data);

      handleClose();
    }
    setValidated(true);
  }

  const handleShow = (id) => {
    fetchDataById(id);
    setShow(true);
  };

  useEffect(() => {
    fetchData();
  }, [pfyId,fetchData]);

  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

    const classifications = [
      { id: 0, description: "Staff" },
      { id: 1, description: "Contractor" },
      { id: 2, description: "Subcontractor" },
      { id: 3, description: "Volunteer" },
    ];


  return (
    <Paper elevation={10} style={paperStyle}>
      <Box sx={{ width: "100%" }}>
        <h1>Staff Qualifications</h1>
        <div className="py-2">
          Report all staff, contractors, subcontractors, and volunteers, who
          provide direct PSSF services.
        </div>
        <div className="py-2">
          Only provide information on the qualifications for those individuals
          who have direct client contact in the provision of services as
          outlined in the Service Delivery Schedule (Annex D of contract). Do
          not include information on individuals who only have administrative or
          operational responsibilities and who are not responsible for the
          delivery of services to clients.
        </div>

        {staff && staff.length > 0 && (
          <TableContainer className="py-4">
            <Table>
              <TableHead>
                <TableRow>
                  {Role === "Admin" && <TableCell></TableCell>}
                  <TableCell>Name</TableCell>
                  <TableCell>Classificaton</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staff &&
                  staff.length > 0 &&
                  staff.map((staffPerson) => (
                    <TableRow key={staffPerson.id}>
                      {Role === "Admin" && (
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ maxWidth: 60 }}
                        >
                          <a onClick={(e) => handleShow(staffPerson.id)}>
                            <ModeEditOutlineSharpIcon />
                            Edit
                          </a>
                        </TableCell>
                      )}
                      <TableCell>
                        {staffPerson.firstName}&nbsp;{staffPerson.lastName}
                      </TableCell>
                      <TableCell>{staffPerson.classification}</TableCell>
                      <TableCell>{staffPerson.title}</TableCell>
                      <TableCell>
                        {staffPerson.startDate
                          ? Moment(staffPerson.startDate).format("YYYY-MM-DD")
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {staffPerson.endDate
                          ? Moment(staffPerson.endDate).format("YYYY-MM-DD")
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <StaffModal />
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Staff</Modal.Title>
          </Modal.Header>
          <Form noValidate validated={validated} onSubmit={handleSave}>
            <Modal.Body>
              <div className="row">
                <Form.Group className="col-md-6 mb-2" controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    onChange={handleChange}
                    defaultValue={data.firstName}
                    autoFocus
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-6 mb-2" controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    onChange={handleChange}
                    defaultValue={data.lastName}
                    autoFocus
                    required
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group
                  className="col-lg-6 mb-2"
                  controlId="classification"
                >
                  <Form.Label>Classification</Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    value={data.classification}
                    required
                  >
                    <option>Open this select menu</option>
                    {/**HB: For this one, I am going to store the actual string rather than an ID. I don't think it's worth making a lookup table for four single-word strings. */}
                    {classifications.length > 1 &&
                      classifications.map((option) => (
                        <option key={option.id} value={option.description}>
                          {option.description}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="col-md-6 mb-2" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-sm"
                    onChange={handleChange}
                    defaultValue={data.title}
                    autoFocus
                    required
                  />
                </Form.Group>
              </div>
              <Form.Group className="row px-4" controlId="active">
                <Form.Check
                  type={"checkbox"}
                  id="active"
                  checked={data.active || false}
                  name="active"
                  label="Active/Current"
                  onChange={handleCheck}
                />
              </Form.Group>
              <div className="row">
                <Form.Group
                  className="col-lg-6 mb-2 px-4"
                  controlId="startDate"
                >
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    className="form-control-sm"
                    value={
                      Moment(data.startDate).format("YYYY-MM-DD") || null
                    }
                    onChange={handleChange}
                    autoFocus
                  />
                </Form.Group>

                {!data.active && (
                  <Form.Group
                    className="col-lg-6 mb-2 px-4"
                    controlId="endDate"
                  >
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      required
                      type="date"
                      className="form-control-sm"
                      defaultValue={
                        Moment(data.endDate).format("YYYY-MM-DD") || null
                      }
                      onChange={handleChange}
                      autoFocus
                    />
                  </Form.Group>
                )}
              </div>
              <Form.Group className="mb-2" controlId="pssfServices">
                <Form.Label>
                  List any PSSF services this individual provides (ex. Behavior
                  Management, Parent Education, Therapy)
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="form-control-sm"
                  defaultValue={data.pssfServices}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="qualifications">
                <Form.Label>
                  List relevant qualifications, certifications, and training
                  (include training specific to EBM) this individual has in
                  order to provide the direct services listed above
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="form-control-sm"
                  defaultValue={data.qualifications}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Label>
                Identify which following have been completed by the individual
              </Form.Label>
              <div className="row">
                <Form.Group
                  className="col-lg-6 px-4"
                  controlId="backgroundCheck"
                >
                  <Form.Check
                    type={"checkbox"}
                    id="backgroundCheck"
                    checked={data.backgroundCheck || false}
                    name="backgroundCheck"
                    label="Background Check"
                    onChange={handleCheck}
                  />
                </Form.Group>
                <Form.Group
                  className="col-lg-6 px-4"
                  controlId="mandatedReportingTraining"
                >
                  <Form.Check
                    type={"checkbox"}
                    id="mandatedReportingTraining"
                    checked={data.mandatedReportingTraining || false}
                    name="mandatedReportingTraining"
                    label="Mandated Reporting Training"
                    onChange={handleCheck}
                  />
                </Form.Group>
                <Form.Group
                  className="col-lg-6 px-4"
                  controlId="safeSleepForGeorgiaBabies"
                >
                  <Form.Check
                    type={"checkbox"}
                    id="safeSleepForGeorgiaBabies"
                    checked={data.safeSleepForGeorgiaBabies || false}
                    name="safeSleepForGeorgiaBabies"
                    label="Safe Sleep for Georgia Babies"
                    onChange={handleCheck}
                  />
                </Form.Group>
                <Form.Group
                  className="col-lg-6 px-4"
                  controlId="traumaInformedPractice"
                >
                  <Form.Check
                    type={"checkbox"}
                    id="traumaInformedPractice"
                    checked={data.traumaInformedPractice || false}
                    name="traumaInformedPractice"
                    label="Trauma Informed Practice"
                    onChange={handleCheck}
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>

              <Button type="submit">Save Changes</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Box>
    </Paper>
  );
};

export default StaffQualifications;
