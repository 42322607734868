import http from "./httpService";

const apiEndpointCreate = "/Contact/CreateContact";
const apiEndpointByAgencyId = "/Contact/GetContactByAgencyId";
const apiEndpointById = "/Contact";

function contactAgencyUrl(id) {
  return `${apiEndpointByAgencyId}/${id}`;
}

function contactUrl(id) {
  return `${apiEndpointById}/${id}`;
}

export function getContactByAgencyId(id) {
  return http.get(contactAgencyUrl(id));
}

export function getContactById(id) {
  return http.get(contactUrl(id));
}

export function saveContact(Contact) {
  if (Contact.id) {
    const body = { ...Contact };
    delete body.id;
    return http.post(contactUrl(Contact.id), body);
  }
}

export function createContact(Contact) {
  const body = { ...Contact };
  return http.post(apiEndpointCreate, body);
}
