import { Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import { fetchHtmlPageByName } from "../../services/htmlPageService";

function TrainingResources() {
  const paperStyle = {
    padding: 20,
    height: "100%",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };

  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    fetchHtmlPageByName("trainingResources")
      .then((response) => {
        setHtmlContent(response.data.content);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the HTML page content:", error);
        setError("Failed to load content");
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>; // Display loading indicator
  if (error) return <div>{error}</div>; // Display error message if any

  return (
    <Paper elevation={10} style={paperStyle}>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Paper>
  );
}

export default TrainingResources;
