import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as Yup from "yup";
import {
  getResourceGuideByFiscalYearandId,
  saveResourceGuide,
  saveDraftResourceGuide,
} from "../../services/resourceGuideService";
import { createContact, saveContact } from "../../services/getContactService";
import { updateProgramById } from "../../services/getProgramService";

// Define your Yup validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .when("contactId", {
      is: (value) => value !== null && value !== undefined,
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  email: Yup.string()
    .trim()
    .email("Invalid email format") // Default email check
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, // Additional regex check for the domain part
      "Email must have a valid domain"
    )
    .when("contactId", {
      is: (value) => value !== null && value !== undefined,
      then: (schema) => schema.required("Email is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  phone: Yup.string()
    .trim()
    .matches(
      /^(\+?\d{1,2}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/,
      "Phone number is not valid"
    )
    .when("contactId", {
      is: (value) => value !== null && value !== undefined,
      then: (schema) => schema.required("Phone is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  completedBy: Yup.string().trim().required("This field is required"), // Always required
});

const SonResourceGuideForm = ({
  programId,
  fiscalYear,
  counties,
  program,
  agency,
}) => {
  const [data, setData] = useState({});
  const [data2, setData2] = useState({});
  const [disableInputs, setDisableInputs] = useState(false);
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isErrorDialog, setIsErrorDialog] = useState(false); // To differentiate success/error
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const rgContact = program.contacts.find(
        (e) => e.type === "Resource Guide Contact"
      );

      if (rgContact) {
        setData2({
          contactId: rgContact.id,
          programId: program.id,
          name: rgContact.name,
          email: rgContact.email,
          phone: rgContact.phone,
          type: rgContact.type,
          confidentialAddress: program.confidentialAddress,
        });
      } else {
        setDisableInputs(false);
        setData2({
          programId: program.id,
          name: "",
          email: "",
          phone: "",
          type: "Resource Guide Contact",
          confidentialAddress: program.confidentialAddress,
        });
      }

      const { data } = await getResourceGuideByFiscalYearandId(
        fiscalYear,
        programId
      );
      const { data: lastYearData } = await getResourceGuideByFiscalYearandId(
        fiscalYear - 1,
        programId
      );

      if (data || lastYearData) {
        const disableInputsCondition =
          !!data.dateApprovedByAdmin || checkDisableInputs(data);
        setDisableInputs(disableInputsCondition);
        setData({
          requestedEdits: data?.draftRequestedEdits || data?.requestedEdits,
          programDescription:
            data?.draftProgramDescription ||
            data?.programDescription ||
            lastYearData?.programDescription,
          additionalServicesOffered:
            data?.draftAdditionalServicesOffered ||
            data?.additionalServicesOffered ||
            lastYearData?.additionalServicesOffered,
          completedBy: data?.draftCompletedBy || data?.completedBy,
        });
      }
    };

    fetchData();
  }, [fiscalYear, programId, program]);

  const checkDisableInputs = (data) => {
    return (
      !!data.requestedEdits ||
      !!data.programDescription ||
      !!data.additionalServicesOffered ||
      !!data.completedBy
    );
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(
        {
          name: data2.name,
          email: data2.email,
          phone: data2.phone,
          completedBy: data.completedBy,
          contactId: data2.contactId,
        },
        { abortEarly: false }
      );

      setErrors({}); // Clear errors if validation passes
      return true;
    } catch (err) {
      console.error("Validation error:", err);

      const validationErrors = {};

      if (err.inner) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }

      setErrors(validationErrors);

      setDialogMessage("Please check required fields!");
      setIsErrorDialog(true); // Error dialog
      setOpenDialog(true); // Open dialog
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.currentTarget;

    if (["name", "email", "phone", "confidentialAddress"].includes(name)) {
      const updatedData2 = {
        ...data2,
        [name]: type === "checkbox" ? checked : value,
      };
      setData2(updatedData2);
    } else {
      const updatedData = { ...data, [name]: value };
      setData(updatedData);
    }
  };

  const saveDraft = async () => {
    const isValid = await validateForm();
    if (!isValid) return;

    setLoading(true); // Start loading

    const contact = {
      id: data2.contactId,
      name: data2.name,
      email: data2.email,
      phone: data2.phone,
      type: data2.type,
      programId: data2.programId
    };

    const programUpdate = {
      ...program,
      confidentialAddress: data2.confidentialAddress,
    };

    try {
      if (data2.contactId) {
        await saveContact(contact);
      } else {
        const response = await createContact(contact);
        const updatedContact = response.data;
        setData2((prevState) => ({
          ...prevState,
          contactId: updatedContact.id, 
          ...updatedContact, 
        }));
      }

      await updateProgramById(programUpdate);
      await saveDraftResourceGuide(programId, fiscalYear, data);
      setDialogMessage("Draft saved successfully!");
      setIsErrorDialog(false); // Success popup
      setOpenDialog(true);
    } catch (error) {
      console.log("Error:", error);
      setDialogMessage("Failed to save draft");
      setIsErrorDialog(true); // Error popup
      setOpenDialog(true);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const doSubmit = async () => {
    const isValid = await validateForm();
    if (!isValid) return;

    setLoading(true); // Start loading

    const contact = {
      id: data2.contactId,
      name: data2.name,
      email: data2.email,
      phone: data2.phone,
      type: data2.type,
      programId: data2.programId,
    };

    const programUpdate = {
      ...program,
      confidentialAddress: data2.confidentialAddress,
    };

    try {
      if (data2.contactId) {
        await saveContact(contact);
      } else {
        const response = await createContact(contact);
        const updatedContact = response.data;
        setData2((prevState) => ({
          ...prevState,
          contactId: updatedContact.id,
          ...updatedContact,
        }));
      }

      await updateProgramById(programUpdate);
      await saveResourceGuide(programId, fiscalYear, data);
      setDialogMessage("Resource guide saved successfully!");
      setDisableInputs(true);
      setIsErrorDialog(false); // Success popup
      setOpenDialog(true);
    } catch (error) {
      console.log("Error:", error);
      setDialogMessage("Failed to save resource guide");
      setIsErrorDialog(true); // Error popup
      setOpenDialog(true);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box component="form" onSubmit={(e) => e.preventDefault()} noValidate>
      <Box sx={{ padding: 2, paddingTop: 8 }}>
        <Typography variant="h6" gutterBottom>
          {data2.type}
        </Typography>
        <Grid container spacing={2}>
          <Grid container sx={{ paddingLeft: 21 }}>
            <Grid
              container
              item
              spacing={2}
              xs={12}
              sm={8}
              md={8}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <InputLabel id="name-label">Name:</InputLabel>
                <TextField
                  fullWidth
                  name="name"
                  value={data2.name || ""}
                  onChange={handleChange}
                  disabled={disableInputs}
                  variant="outlined"
                  size="small"
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="email-label">Email:</InputLabel>
                <TextField
                  fullWidth
                  name="email"
                  value={data2.email || ""}
                  onChange={handleChange}
                  disabled={disableInputs}
                  variant="outlined"
                  size="small"
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="phone-label">Phone:</InputLabel>
                <TextField
                  fullWidth
                  name="phone"
                  value={data2.phone || ""}
                  onChange={handleChange}
                  disabled={disableInputs}
                  variant="outlined"
                  size="small"
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="confidentialAddress"
                      checked={!!data2.confidentialAddress}
                      onChange={handleChange}
                      disabled={disableInputs}
                      size="small"
                    />
                  }
                  label="Program Address is confidential"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InputLabel id="requestedEdits-label">
              Please review and verify the above information. If the information
              is not correct, describe required edits:
            </InputLabel>
            <TextField
              fullWidth
              name="requestedEdits"
              value={data.requestedEdits || ""}
              onChange={handleChange}
              disabled={disableInputs}
              variant="outlined"
              multiline
              minRows={4}
              maxRows={10}
              InputProps={{
                style: {
                  resize: "vertical",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  overflow: "auto",
                },
                "& .MuiInputBase-input": {
                  resize: "vertical",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              id="programDescription-label"
              sx={{ whiteSpace: "normal" }}
            >
              Provide a brief description of the PSSF program at your agency. As
              part of the Resource Guide that will be utilized by DFCS staff and
              other community-based family services agencies, the description
              should include your intended target population and referral
              criteria, service objectives, and expected outcomes (limit 250
              words):
            </InputLabel>
            <TextField
              fullWidth
              name="programDescription"
              value={data.programDescription || ""}
              onChange={handleChange}
              disabled={disableInputs}
              variant="outlined"
              multiline
              minRows={4}
              maxRows={10}
              InputProps={{
                style: {
                  resize: "vertical",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  overflow: "auto",
                },
                "& .MuiInputBase-input": {
                  resize: "vertical",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              id="additionalServicesOffered-label"
              sx={{ whiteSpace: "normal" }}
            >
              List any non-PSSF-funded services at your agency available to the
              families you serve. (DO NOT LIST SERVICES FROM YOUR SERVICE
              DELIVERY SCHEDULE. These will be listed in a separate section in
              the Resource Guide.) Describe each additional service one at a
              time in the box below. (Maximum 150 characters per service.):
            </InputLabel>
            <TextField
              fullWidth
              name="additionalServicesOffered"
              value={data.additionalServicesOffered || ""}
              onChange={handleChange}
              disabled={disableInputs}
              variant="outlined"
              multiline
              minRows={4}
              maxRows={10}
              InputProps={{
                style: {
                  resize: "vertical",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  overflow: "auto",
                },
                "& .MuiInputBase-input": {
                  resize: "vertical",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="completedBy-label">
              Please enter the name of the individual that has completed this
              form.
            </InputLabel>
            <TextField
              fullWidth
              name="completedBy"
              value={data.completedBy || ""}
              onChange={handleChange}
              disabled={disableInputs}
              variant="outlined"
              error={!!errors.completedBy}
              helperText={errors.completedBy}
            />
          </Grid>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={saveDraft}
                disabled={disableInputs || loading} // Disable button when loading
              >
                {loading ? <CircularProgress size={24} /> : "Save Draft"}{" "}
                {/* Show spinner */}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={doSubmit}
                disabled={disableInputs || loading} // Disable button when loading
              >
                {loading ? <CircularProgress size={24} /> : "Save"}{" "}
                {/* Show spinner */}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Dialog Popup for Save or Validation Error Notifications */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isErrorDialog ? "Validation Error" : "Notification"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SonResourceGuideForm;
