import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Paper,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchHtmlPageByName,
  fetchAllHtmlPageNames,
  saveHtmlPage,
  updateHtmlPage,
} from "../../services/htmlPageService";
import TinyMCE from "../admin/tinyMCE";

const EditHtmlPage = () => {
  const defaultData = {
    name: "", // New field for page identification string
    title: "",
    description: "",
    content: "",
  };

  const [pageData, setPageData] = useState(defaultData);
  const [isLoading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [pageNames, setPageNames] = useState([]); // To hold the list of page names
  const [selectedPageName, setSelectedPageName] = useState(""); // To hold the selected page name
  const editorRef = useRef(null);

  // Fetch all page names on component mount
  useEffect(() => {
    const loadPageNames = async () => {
      setLoading(true);
      try {
        const { data } = await fetchAllHtmlPageNames();
        setPageNames(data);
      } catch (error) {
        console.error("Error fetching page names:", error);
      } finally {
        setLoading(false);
      }
    };
    loadPageNames();
  }, []);

  // Load the selected HTML page by identification string
  const loadHtmlPage = async () => {
    if (!selectedPageName) return;

    setLoading(true);
    try {
      const savedPage = await fetchHtmlPageByName(selectedPageName);
      if (savedPage && savedPage.data) {
        setPageData(savedPage.data);
        setDialogMessage("Page loaded successfully");
      } else {
        setDialogMessage("Page not found");
      }
    } catch (error) {
      console.error("Error loading page data:", error);
      setDialogMessage("Error loading page");
    } finally {
      setLoading(false);
      setDialogOpen(true);
    }
  };

  // Handler to save the current HTML page data
  const handleSave = async () => {
    setLoading(true);
    try {
      if (pageData.name) {
        await updateHtmlPage(pageData);
        setDialogMessage("Page updated successfully");
      } else {
        await saveHtmlPage(pageData);
        setDialogMessage("Page saved successfully");
      }
    } catch (error) {
      console.error("Error saving page data:", error);
      setDialogMessage("Error saving page");
    } finally {
      setLoading(false);
      setDialogOpen(true);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          marginTop: 150,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <CircularProgress />
        </Stack>
      </div>
    );
  }

  return (
    <>
      <Paper
        elevation={3}
        style={{ padding: 20, margin: "20px auto", width: "80%" }}
      >
        <Typography variant="h4">Edit HTML Page</Typography>

        {/* Dropdown to select a page name */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Page</InputLabel>
          <Select
            value={selectedPageName}
            onChange={(e) => setSelectedPageName(e.target.value)}
          >
            {pageNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={loadHtmlPage}
          disabled={!selectedPageName}
        >
          Load Page
        </Button>

        {/* Title and description fields */}
        <TextField
          label="Title"
          value={pageData.title}
          onChange={(e) =>
            setPageData((prev) => ({ ...prev, title: e.target.value }))
          }
          fullWidth
          margin="normal"
        />

        <TextField
          label="Description"
          value={pageData.description}
          onChange={(e) =>
            setPageData((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />

        {/* TinyMCE Editor for the page content */}
        <TinyMCE
          value={pageData.content}
          onChange={(content) => setPageData((prev) => ({ ...prev, content }))}
          editorRef={editorRef}
        />

        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Paper>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditHtmlPage;
