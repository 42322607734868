import { React, useState, useEffect } from "react";
import { Paper, Box, Avatar, Stack, Button } from "@mui/material";
import {
  CalendarMonthOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import { getAgencyByFiscalYearandId } from "../../services/getAgencyService";
import { getProgramsByIdandFiscalYear } from "../../services/getProgramService";
import { downloadFile } from "../../services/downloadService";

const AwardLetter = ({ user, soNFiscalYear }) => {
  const [program, setProgram] = useState("");
  const [agency, setAgency] = useState("");
  const [SonsID] = useState(user.name);
  const fy = soNFiscalYear;
  const programId = user ? user.ProgramId : null;
  const environment = process.env.REACT_APP_ENV;

  async function fetchData() {
    if (programId !== null) {
      const { data: program } = await getProgramsByIdandFiscalYear(
        programId,
        fy
      );
      setProgram(program);
      const { data: agency } = await getAgencyByFiscalYearandId(
        fy,
        program.agencyId
      );
      setAgency(agency);
    }
  }

  useEffect(() => {
    if (fy) fetchData();
  }, [programId, fy]);

  const paperStyle = {
    padding: 20,
    //height: "100vh",
    maxWidth: 1000,
    margin: "20px auto",
    justify: "flex-end",
  };
  const linkStyle = {
    textDecoration: "none",
  };
  const wordWrapStyle = { overflowWrap: "break-word", maxWidth: 900 };
  const avatarStyle = {
    backgroundColor: "white",
    outline: "none",
  };
  const pdfIconStyle = {
    color: "black",
    textDecoration: "none",
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      <Box>
        <div id="contract_award_letter">
          <h1>Award Information</h1>
          <div>
            Congratulations! Your program has received an award for the upcoming
            fiscal year. Please download a copy of your award letter here:
          </div>
          <br />
          <div>
            <Stack direction="row" alignItems="center">
              <Button
                onClick={() =>
                  downloadFile(`${fy}_Awards`, `${user.name}_awardletter.pdf`)
                }
              >
                FFY{fy} Award Letter
              </Button>
              <Avatar sx={avatarStyle}>
                <PictureAsPdfOutlined color="warning"></PictureAsPdfOutlined>
              </Avatar>
            </Stack>
          </div>
          <div>
            <div>Agency: {agency.name}</div>
          </div>
          <div>
            <div>Program: {program.name}</div>
          </div>
          <div>
            <div>Program ID: {program.id}</div>
          </div>
          <div>
            <div>SoN: {SonsID}</div>
          </div>
          <div>
            <div>
              Model:{" "}
              {program.serviceModel &&
                `${program.serviceModel.serviceModelCategoryAbrv}/${program.serviceModel.modelAbrv}`}
            </div>
          </div>
          <br />
          <div>
            {
              "You are required to register for and attend the following zoom meetings:"
            }
          </div>
          <br />
          <div id="contract_meeting">
            <b>{`FFY${fy} Contract Preparation Meeting`}</b>
            <div>
              <Stack direction="row" alignItems="center">
                <Avatar sx={avatarStyle}>
                  <CalendarMonthOutlined color="primary"></CalendarMonthOutlined>
                </Avatar>
                <div>
                  <div>Thursday, July 25, 2024</div>
                  <div>10:00am-11:00am EST via Zoom</div>
                  {"Register in advance using this link:"}&nbsp;
                  <a
                    href="https://us02web.zoom.us/meeting/register/tZMocuyrqjMrHNLxayCmPhjJ_vFw8gd4dJax"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={linkStyle}
                  >
                    Zoomlink
                  </a>
                </div>
              </Stack>
            </div>
          </div>

          <div id="contract_meeting">
            <b>{`FFY${fy} Contractor Meeting`}</b>
            <div>
              <Stack direction="row" alignItems="center">
                <Avatar style={avatarStyle}>
                  <CalendarMonthOutlined color="primary"></CalendarMonthOutlined>
                </Avatar>
                <div>
                  <div>Thursday, September 26, 2024</div>
                  <div>10:00am-12:00pm EST via Zoom</div>
                  {"Register in advance using this link:"}&nbsp;
                  <a
                    href="https://us02web.zoom.us/meeting/register/tZYod-6srTIpG9Om0OVlWpBtb6GGXxijcf3o"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={linkStyle}
                  >
                    Zoomlink
                  </a>
                </div>
              </Stack>
            </div>
          </div>

          <div id="contract_meeting">
            <b>  {environment === "Demo" ? "Care Solutions" : "PSSFWeb"} 101 Training & New Provider Orientation</b>
            <div>
              Please register anyone in your program who will need {environment === "Demo" ? "Care Solutions" : "PSSFWeb"} 
              access. New Provider orientation will be held immediately
              following {environment === "Demo" ? "Care Solutions" : "PSSFWeb"}  training.
            </div>
            <div>
              <Stack direction="row" alignItems="center">
                <Avatar sx={avatarStyle}>
                  <CalendarMonthOutlined color="primary"></CalendarMonthOutlined>
                </Avatar>
                <div>
                  <div>Thursday, October 17, 2024</div>
                  <div>10:00am-12:00pm EST via Zoom</div>
                  {"Register in advance using this link:"}&nbsp;
                  <a
                    href="https://us02web.zoom.us/meeting/register/tZUvduqgrjwqGtWShzG0a1lQHgsa1qydNhh1"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={linkStyle}
                  >
                    Zoomlink
                  </a>
                </div>
              </Stack>
            </div>
          </div>
          <br />
        </div>
      </Box>
    </Paper>
  );
};

export default AwardLetter;
