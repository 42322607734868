import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getProgramsNamesFiscalYear } from "../../services/getProgramService";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CustomDataTable from "../common/customDataTable";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");

export default function ProgramsWithReportsTable({
  isLoading,
  setIsLoading,
  fiscalYear,
  programsReports,
  reportingPeriod,
  periodId,
}) {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const [rows, setRows] = useState([]);

  async function fetchData() {
    setIsLoading(true);
    const { data: rowsData } = await getProgramsNamesFiscalYear(fiscalYear);

    const alertStatuses = {
      0: "Unlocked",
      1: "Locked",
      2: "Approved",
      3: "Done",
    };

    const updatedRows = rowsData.map((row) => {
      const programReport = programsReports.find(
        (program) => program.programId === row.id
      );

      if (programReport) {
        row.alertStatus =
          alertStatuses[programReport.alertStatus] || "Unlocked";
        row.alertCreated = programReport.alertCreated
          ? dayjs
              .tz(programReport.alertCreated, "UTC")
              .tz("America/New_York")
              .format("YYYY-MM-DD h:mm A")
          : "-";
        row.alertResolved = programReport.alertResolved
          ? dayjs
              .tz(programReport.alertResolved, "UTC")
              .tz("America/New_York")
              .format("YYYY-MM-DD h:mm A")
          : "-";
        row.reportBegan = true;
        row.programmaticReportComplete =
          programReport.programmaticReportComplete;
        if (row.programmaticReportComplete) {
          row.programmaticReportCompleteTimeStamp = dayjs
            .tz(programReport.programmaticReportCompleteTimeStamp, "UTC")
            .tz("America/New_York")
            .format("YYYY-MM-DD h:mm A");
        }
      } else {
        row.alertStatus = "Unlocked";
        row.reportBegan = false;
        row.programmaticReportComplete = false;
      }

      return row;
    });

    setRows(updatedRows);
    setIsLoading(false);
  }

  function runToastSwitchedToProgram(value) {
    toast.success(`Switching you to program ${value}`, {
      position: "top-right",
      autoClose: 1000,
      theme: "dark",
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 1001);
  }

  const handleBtnSwitchProgram = (value) => {
    sessionStorage.setItem("ProgramId", value);
    sessionStorage.setItem("showNavSubMenu", true);
    sessionStorage.removeItem("UserId");
    sessionStorage.removeItem("Username");

    runToastSwitchedToProgram(value);
  };

  useEffect(() => {
    fetchData();
  }, [programsReports]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          marginTop: 150,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack spacing={2} direction="row">
          <CircularProgress />
        </Stack>
      </div>
    );
  }

  // Define the columns for StyledDataGrid
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "reportingPeriod",
      headerName: "Reporting Period",
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return `${reportingPeriod || periodId} | ${fiscalYear}`;
      },
    },
    {
      field: "agencyName",
      headerName: "Agency",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "programStatus",
      headerName: "Active",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <CancelIcon style={{ color: "darkorange" }} />
        );
      },
    },
    {
      field: "reportBegan",
      headerName: "Report Started",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <CancelIcon style={{ color: "darkorange" }} />
        );
      },
    },
    {
      field: "programmaticReportComplete",
      headerName: "Report Finished",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <CancelIcon style={{ color: "darkorange" }} />
        );
      },
    },
    {
      field: "programmaticReportCompleteTimeStamp",
      headerName: "Finished TimeStamp",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.value ? dayjs(params.value).format("L LT") : "-";
      },
    },
    {
      field: "doneLockStatus",
      headerName: "Lock Status",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value == parseInt(reportingPeriod) ? (
          <>
            <EnhancedEncryptionIcon className="text-danger" />
            <span
              className="text-danger fw-bold"
              style={{ display: "block", marginTop: "5px" }}
            >
              Locked
            </span>
          </>
        ) : (
          <>
            <LockOpenIcon className="text-success" />
            <span
              className="text-success fw-bold"
              style={{ display: "block", marginTop: "5px" }}
            >
              Unlocked
            </span>
          </>
        );
      },
    },
    {
      field: "alertCreated",
      headerName: "Alert Created",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value ? dayjs(params.value).format("L LT") : "-";
      },
    },
    {
      field: "alertResolved",
      headerName: "Alert Resolved",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value ? dayjs(params.value).format("L LT") : "-";
      },
    },
    {
      field: "actions",
      headerName: "-",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button onClick={() => handleBtnSwitchProgram(params.row.id)}>
          Switch to Program
        </Button>
      ),
    },
  ];

  return (
    <Box className="col">
      <ToastContainer position="top-right" autoClose={5000} theme="dark" />

      <Box sx={{ width: "99%" }}>
        <CustomDataTable
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowHeight={120}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
}
