import http from "./httpService";

const apiEndpoint = "/htmlPages";

export function getHtmlPageUrlByName(name) {
  return `${apiEndpoint}/${name}`;
}

export function updateHtmlPageByNameUrl(name) {
  return `${apiEndpoint}/${name}`;
}

// Fetch the HTML page by identification string (GET request)
export function fetchHtmlPageByName(name) {
  return http.get(getHtmlPageUrlByName(name));
}

// Fetch all HTML page names (GET request)
export function fetchAllHtmlPageNames() {
  return http.get(`${apiEndpoint}/names`);
}

// Save a new HTML page (POST request)
export function saveHtmlPage(body) {
  return http.post(apiEndpoint, body);
}

// Update an existing HTML page by identification string (PUT request)
export function updateHtmlPage(body) {
  return http.put(updateHtmlPageByNameUrl(body.name), body);
}
